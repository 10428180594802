import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/shared/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import TimberIDAuthenticationPlatform
    from "../../components/sections/TimberIDAuthenticationPlatform/TimberIDAuthenticationPlatform";


const TimberIDAuthenticationPlatformPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.OfferingTimberIDAuthenticationPlatform.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={pageTitle}
                parentTitles={[routes.Offering.pageTitle]}
                parentRoutes={[routes.Offering]}
            />

            <TimberIDAuthenticationPlatform/>

        </Layout>
    );
};

export default TimberIDAuthenticationPlatformPage;
